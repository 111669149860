import { FC, useEffect, useMemo } from 'react'

import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'

import { Card } from 'components/common'

import pvw from 'static/icons/projects/pvw.png'
import typewriter from 'static/icons/projects/typewriter.png'
import dartz from 'static/icons/projects/dartz.png'

import stars from 'static/images/stars.png'

import { useRefContext } from 'context/RefContext'

import theme from 'theme'


interface ProjectI {
	name: string
	icon: string
	text: string
	link?: string | {
		'ios': string
		'android': string
	}
}


const ProjectsView: FC = () => {

	const { t, i18n } = useTranslation()

	const { ProjectsRef } = useRefContext()

	useEffect(() => {
		window.addEventListener('scroll', () => {
			const projects = document.getElementById('projects')
			if (projects) {
				projects.style.backgroundPositionY = `${window.scrollY / 2}px`
			}
		})
	}, [])

	const projects: ProjectI[] = useMemo(() => {
		return [
			{
				name: 'PVonWeb',
				icon: pvw,
				text: t('A company\'s transcripts management web app')
			},
			{
				name: 'Darts Scoreboard',
				icon: dartz,
				text: t('A darts mobile application to keep track of your scores'),
				link: {
					'android': 'https://play.google.com/store/apps/details?id=com.tommyriquet.dartz',
					'ios': 'https://apps.apple.com/us/app/darts-simple-scoreboard/id6476447091'
				}
			},
			{
				name: 'Typewriter',
				icon: typewriter,
				text: t('A minimalistic typing game to improve your typing skills'),
				link: 'https://riquet.dev/Typewriter'
			}
		]
	}, [i18n.language])

	return (
		<Box
			ref={ProjectsRef}
			id='projects'
			display='flex'
			flexDirection='column'
			justifyContent='space-around'
			alignItems='center'
			paddingY={16}
			paddingX={5}
			sx={{
				backgroundColor: theme => theme.palette.primary.main,
				backgroundImage: `url(${stars})`,
				backgroundSize: '570px auto',
				backgroundRepeat: 'repeat'
			}}
		>
			<Typography
				variant='h2'
				sx={{
					color: 'white',
					position: 'relative',
					display: 'flex',
					flexDirection: 'row',
					marginBottom: 4,
					zIndex: 2
				}}>
				<Typography variant='h2' noWrap>
					{t('Some of my')}&nbsp;
				</Typography>
				<Box position='relative'>
					<svg
						width='100%'
						style={{
							position: 'absolute',
							zIndex: -1,
							height: '.3em',
							left: '0',
							right: '0',
							bottom: '-0.2em',
							backgroundSize: '100% 100%',
							backgroundRepeat: 'no-repeat',
							backgroundPosition: 'bottom'
						}}
					>
						<path
							fill={theme.palette.primary.dark}
							fillRule='evenodd'
							d='M2.8 8.3l16.8-.6L35 7.1l15-.7c2.2-.1 4.5-.3 6.8-.3l10-.2h5.5l10.3.2 15.4.6L129.5 8l15.5.8 15.4.7c9.7.4 19.4.7 29 1.3l8 .5 8.9.7 7.7.7 3.2.3 3.7.5 5.2.6 6.6 1.2.6.1a2.3 2.3 0 002.3-1.6c.2-.6.1-1.3-.2-1.9-.2-.4-.8-1-1.3-1.1l-4.2-.8-4.2-.7A403.3 403.3 0 00212 7.7a2.4 2.4 0 001-2.4c-.2-.6-.6-1.2-1-1.5l-1.1-.5a33 33 0 00-2.6-.4l-2-.3-3.8-.2L195 2l-15.5-.5-15.4-.3L156 1h-24.4L115.8.8C106.1.7 96.2.6 86.4.7H71.8c-5.2 0-10.4 0-15.6.2L45.9 1l-5.9.1-9.4.4-6.2.3c-7.4.5-14.7 1-22 1.7-.5.1-.8.3-1.1.6-.5.4-.8 1-.8 1.6 0 1.3 1 2.5 2.3 2.5'
						/>
					</svg>
					{t('projects')}
				</Box>
			</Typography>
			<Typography variant='body1' sx={{ color: theme => theme.palette.primary.contrastText, marginBottom: 8 }}>
				{t('From concept to reality, explore projects that define my passion')}
			</Typography>
			<Grid container mt={2} maxWidth='lg' spacing={4}>
				{
					projects.map((project, index) => <Card key={project.name} index={index} item={project} breakpoints={{xs: 12, sm: 12, md: 12, lg: 4}}/>)
				}
			</Grid>
		</Box>
	)
}

export default ProjectsView
